import { ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

import "./App.css";
import MainPage from "./components/MainPage";
import theme from "./config/theme";

function vw(v) {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return (v * w) / 100 - 10;
}
function App() {
  React.useEffect(() => {});
  const isMobile = useMediaQuery("(max-width: 500px)");

  return (
    <ThemeProvider theme={theme}>
      <MainPage></MainPage>
    </ThemeProvider>
  );
}

export default App;
