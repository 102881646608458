import { createTheme } from "@mui/material/styles";
import COLOR_PALETTE from "./color-palete";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Nickainley",
      "Roboto",
      // '"Helvetica Neue"',
      //     "Arial",
      //     "sans-serif",
      //     '"Apple Color Emoji"',
      //     '"Segoe UI Emoji"',
      //     '"Segoe UI Symbol"',
      //     '"Roboto Condensed"',
    ].join(","),
  },
  palette: {
    primary: { main: COLOR_PALETTE.White },
    secondary: { main: COLOR_PALETTE.Pink },
  },
  overrides: {},
});

export default theme;
