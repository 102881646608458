import { Email } from "@mui/icons-material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

export default function Contact(props) {
  const isMobile = useMediaQuery("(max-width: 500px)");
  React.useEffect(() => {});

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        <Typography
          component="h5"
          variant="h5"
          color="primary"
          style={{
            fontFamily: "RobotoCondensed",
          }}
        >
          <Box fontWeight={800}>Contact</Box>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
              startIcon={
                <LinkedInIcon fontSize={isMobile ? "medium" : "large"} />
              }
              onClick={() => {
                window.location.assign(
                  "https://www.linkedin.com/in/patricia-milou/"
                );
              }}
            >
              <Typography
                component="h6"
                variant="h6"
                style={{
                  fontFamily: "RobotoCondensed",
                }}
              >
                LinkedIn
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              size="large"
              startIcon={<Email fontSize={isMobile ? "medium" : "large"} />}
              href="mailto:contact@patriciamilou.com"
            >
              <Typography
                component="h6"
                variant="h6"
                style={{
                  fontFamily: "RobotoCondensed",
                }}
              >
                Email
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
