import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

export default function Job(props) {
  const isMobile = useMediaQuery("(max-width: 500px)");
  React.useEffect(() => {});
  return (
    <Grid
      container
      direction="row"
      justifyContent={
        props.index % 2 == 0 && isMobile ? "flex-start" : "flex-end"
      }
    >
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            {props.company ? (
              <Typography
                component="div"
                variant={isMobile ? "subtitle1" : "h6"}
                color="primary"
                style={{
                  fontFamily: "RobotoCondensed",
                  // textAlign: props.index % 2 == 0 ? "left" : "right",
                }}
              >
                <Box letterSpacing={0} fontWeight={isMobile ? 700 : 500}>
                  {props.company + " | " + props.position}
                </Box>
              </Typography>
            ) : props.task ? (
              <Typography
                component="div"
                variant={isMobile ? "subtitle1" : "h6"}
                color="primary"
                style={{
                  fontFamily: "RobotoCondensed",
                  // textAlign: props.index % 2 == 0 ? "left" : "right",
                }}
              >
                <Box letterSpacing={0} fontWeight={isMobile ? 700 : 500}>
                  {props.task}
                </Box>
              </Typography>
            ) : null}
          </Grid>{" "}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        // style={{ textAlign: props.index % 2 == 0 ? "left" : "right" }}
      >
        <Grid item xs={12}>
          <Typography
            component="div"
            variant="body2"
            color="primary"
            style={{
              fontFamily: "RobotoCondensed",
            }}
          >
            <Box fontWeight={400}>
              {props.dateS}
              {props.dateE ? "-" + props.dateE : null}
              {props.location ? "|" + props.location : null}
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "7px" }}>
        <Typography
          component="div"
          variant="subtitle2"
          color="primary"
          style={{
            fontFamily: "RobotoCondensed",
            // textAlign: props.index % 2 == 0 ? "left" : "right",
          }}
        >
          <Box fontWeight={300}>{props.desc}</Box>
        </Typography>
      </Grid>
    </Grid>
  );
}
