import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function EducationStart(props) {
  React.useEffect(() => {});
  return (
    <Grid
      container
      direction="column"
      justifyContent={props.index % 2 == 0 ? "flex-start" : "flex-end"}
    >
      <Grid item>
        <Typography
          component="div"
          variant={props.isMobile ? "subtitle1" : "h6"}
          color="primary"
          style={{
            fontFamily: "RobotoCondensed",
            // textAlign: props.index % 2 == 0 ? "left" : "right",
          }}
        >
          <Box letterSpacing={0} fontWeight={props.isMobile ? 700 : 500}>
            Joined the University of Edinburgh
          </Box>
        </Typography>
      </Grid>
      <Grid item>
        <Grid item>
          <Typography
            component="div"
            variant="subtitle2"
            color="primary"
            style={{
              fontFamily: "RobotoCondensed",
              // textAlign: props.index % 2 == 0 ? "left" : "right",
            }}
          >
            <Box letterSpacing={0} fontWeight={400}>
              2016 | Edinburgh
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
