import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

export default function NavBar(props) {
  const isMobile = useMediaQuery("(max-width: 500px)");
  React.useEffect(() => {});

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography
          component="h2"
          variant="h2"
          color="secondary"
          style={{
            textShadow: "1px 1px 1px #FFC4D6",
            textAlign: "center",
          }}
        >
          Patricia Milou
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          textAlign: "center",
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <Button color="primary" onClick={() => props.scrollToAbout()}>
              {" "}
              <Typography
                component="h6"
                variant={isMobile ? "subtitle2" : "h6"}
                color="primary"
                style={{ fontFamily: "RobotoCondensed" }}
              >
                About
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <div
              style={{ height: "30px", width: 1, backgroundColor: "#909090" }}
            ></div>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={() => props.scrollToCertificates()}
            >
              {" "}
              <Typography
                component="h6"
                variant={isMobile ? "subtitle2" : "h6"}
                color="primary"
                style={{ fontFamily: "RobotoCondensed" }}
              >
                Certificates
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <div
              style={{ height: "30px", width: 1, backgroundColor: "#909090" }}
            ></div>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={() => props.scrollToProjects()}>
              {" "}
              <Typography
                component="h6"
                variant={isMobile ? "subtitle2" : "h6"}
                color="primary"
                style={{ fontFamily: "RobotoCondensed" }}
              >
                Projects
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <div
              style={{ height: "30px", width: 1, backgroundColor: "#909090" }}
            ></div>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={() => props.scrollToContact()}>
              {" "}
              <Typography
                component="h6"
                variant={isMobile ? "subtitle2" : "h6"}
                color="primary"
                style={{ fontFamily: "RobotoCondensed" }}
              >
                Contact
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
