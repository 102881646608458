import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

export default function Pin(props) {
  const isMobile = useMediaQuery("(max-width: 500px)");
  React.useEffect(() => {});
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent={
        props.index % 2 == 0 && isMobile ? "flex-start" : "flex-end"
      }
    >
      <Grid item xs={12}>
        <Typography
          component="div"
          variant={props.isMobile ? "subtitle1" : "h6"}
          style={{
            marginBottom: "0px",
            fontFamily: "RobotoCondensed",
          }}
          color="primary"
        >
          <Box fontWeight={props.isMobile ? 700 : 500}>{props.title}</Box>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          component="div"
          variant="body2"
          style={{
            fontFamily: "RobotoCondensed",
          }}
          color="primary"
        >
          <Box letterSpacing={0} fontWeight={400}>
            {props.desc1}
            {props.desc2 ? " | " : null}
            {props.desc2 ? props.desc2 : null}
          </Box>
        </Typography>
      </Grid>

      {props.subheading ? (
        <Grid item xs={12}>
          <Typography
            component="div"
            variant="body2"
            style={{
              fontFamily: "RobotoCondensed",
            }}
            color="primary"
          >
            <Box fontWeight={400}>{props.subheading}</Box>
          </Typography>
        </Grid>
      ) : null}
      {props.desc3 ? (
        <Grid item style={{ marginTop: "5px" }}>
          <Typography
            component="div"
            variant="subtitle2"
            style={{
              fontFamily: "RobotoCondensed",
            }}
            color="primary"
          >
            <Box fontWeight={300}>{props.desc3}</Box>
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}
