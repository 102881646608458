import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

import fulltimeJSON from "../data/fulltime.json";
import internshipsJSON from "../data/internships.json";
import { default as EducationEnd } from "./EducationEnd";
import { default as EducationStart } from "./EducationStart";
import Job from "./Job";

export default function About(props) {
  const fulltime = fulltimeJSON.FullTime;
  const internships = internshipsJSON.Internships;
  const isMobile = useMediaQuery("(max-width: 500px)");
  React.useEffect(() => {
    console.log(isMobile);
  });
  return (
    <Grid
      container
      direction="row"
      style={{ padding: isMobile ? "0px" : "10px", height: "fit-content" }}
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography
          component="h5"
          variant="h5"
          color="primary"
          style={{
            fontFamily: "RobotoCondensed",
          }}
        >
          <Box fontWeight={800}>About me</Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="h6"
          variant={isMobile ? "body1" : "h6"}
          color="primary"
          style={{ fontFamily: "RobotoCondensed" }}
        >
          Hi, I'm Patricia.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="h6"
          variant={isMobile ? "body1" : "h6"}
          color="primary"
          style={{ fontFamily: "RobotoCondensed" }}
        >
          When I first joined the University of Edinburgh for my undergraduate
          studies, I thought that Computer Science was just Software Engineering
          - building products. Soon, I realised that Computer Science is more
          than that. Its capabilities are numerous and I feel blessed for
          choosing this field. I don't think a different degree could fulfil my
          desires more. The projects I have been involved in so far were centred
          around my want to improve peoples' lifestyle and routine. For them, I
          was mostly involved in their SE and UX side while collaborating with
          people who have interest in AI. This has given me the chance to
          explore all three main areas and understand my passion:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="h6"
          variant={isMobile ? "body1" : "h6"}
          color="primary"
          style={{ fontFamily: "RobotoCondensed" }}
        >
          <Box fontStyle="italic">
            Creating well-designed systems by taking in consideration the users’
            needs and wants.
          </Box>
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "fit-content",
          position: isMobile ? "relative" : null,
          right: isMobile ? "87vw" : null,
        }}
      >
        <Timeline
          position={isMobile ? "right" : "alternate"}
          style={{
            width: isMobile ? "175vw" : null,
          }}
        >
          {fulltime.map((job, i) => {
            return (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    variant="default"
                    style={{ backgroundColor: "#FF83A8" }}
                  >
                    {i == 0 ? <WorkIcon /> : null}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {" "}
                  <Job
                    company={job.Company}
                    task={job.Task}
                    position={job.Position}
                    dateS={job.DateS}
                    dateE={job.DateE}
                    location={job.Location}
                    desc={job.Description}
                    index={i}
                  />
                </TimelineContent>
              </TimelineItem>
            );
          })}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                variant="default"
                style={{ backgroundColor: "#FF83A8" }}
              >
                <SchoolIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <EducationEnd index={fulltime.length + 1}></EducationEnd>
            </TimelineContent>
          </TimelineItem>
          {internships.map((internship, i) => {
            return (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    variant="default"
                    style={{ backgroundColor: "#FF83A8" }}
                  />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {" "}
                  <Job
                    company={internship.Company}
                    task={internship.Task}
                    position={internship.Position}
                    dateS={internship.DateS}
                    dateE={internship.DateE}
                    location={internship.Location}
                    desc={internship.Description}
                    index={i}
                  />
                </TimelineContent>
              </TimelineItem>
            );
          })}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                variant="default"
                style={{ backgroundColor: "#FF83A8" }}
              >
                <SchoolIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <EducationStart
                index={fulltime.length + 1 + internships.length + 1}
              ></EducationStart>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Grid>
    </Grid>
  );
}
