import GitHubIcon from "@mui/icons-material/GitHub";
import GroupIcon from "@mui/icons-material/Group";
import LinkIcon from "@mui/icons-material/Link";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

import projectsJSON from "../data/projects.json";
import Pin from "./Pin";

export default function Projects(props) {
  const projects = projectsJSON.Projects;
  const isMobile = useMediaQuery("(max-width: 500px)");
  React.useEffect(() => {});

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        <Typography
          component="h5"
          variant="h5"
          color="primary"
          style={{
            fontFamily: "RobotoCondensed",
            textAlign: "center",
          }}
        >
          <Box fontWeight={800}>Projects</Box>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          height: "fit-content",
          position: isMobile ? "relative" : null,
          right: isMobile ? "87vw" : "0",
        }}
      >
        <Timeline
          position={isMobile ? "right" : "alternate"}
          style={{
            width: isMobile ? "175vw" : null,
          }}
        >
          {projects.map((project, i) => {
            let icon1 = <GitHubIcon fontSize="small"></GitHubIcon>;
            let icon2 = null;
            if (project.URL) {
              icon1 = <LinkIcon fontSize="small"></LinkIcon>;
            }
            if (project.Group) {
              icon2 = (
                <>
                  <GroupIcon fontSize="small"></GroupIcon> {project.Group}
                </>
              );
            }
            return (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    variant="default"
                    style={{ backgroundColor: "#85e0ff" }}
                  />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Pin
                    index={i}
                    title={project.Title}
                    subheading={project.Prize}
                    desc1={project.Technologies}
                    desc2={
                      <>
                        <a
                          href={project.URL ? project.URL : project.Github}
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                            marginRight: "2px",
                          }}
                        >
                          {icon1}
                        </a>
                        {icon2}
                      </>
                    }
                    desc3={project.Description}
                    isMobile={props.isMobile}
                  ></Pin>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Grid>
    </Grid>
  );
}
