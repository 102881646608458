// import LinkIcon from "@mui/icons-material/Link";
// import Timeline from "@mui/lab/Timeline";
// import TimelineConnector from "@mui/lab/TimelineConnector";
// import TimelineContent from "@mui/lab/TimelineContent";
// import TimelineDot from "@mui/lab/TimelineDot";
// import TimelineItem from "@mui/lab/TimelineItem";
// import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
// import Pin from "./Pin";

export default function Certificates(props) {
  const isMobile = useMediaQuery("(max-width: 500px)");
  // let icon = <LinkIcon fontSize="small"></LinkIcon>;
  React.useEffect(() => {});
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        <Typography
          component="h5"
          variant="h5"
          color="primary"
          style={{
            fontFamily: "RobotoCondensed",
          }}
        >
          <Box fontWeight={800}>Certificates</Box>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          height: "fit-content",
          // position: isMobile ? "relative" : null,
          // right: isMobile ? "87vw" : "0",
        }}
      >
        {/* <Timeline
          position={isMobile ? "right" : "alternate"}
          style={{
            width: isMobile ? "175vw" : null,
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                variant="default"
                style={{ backgroundColor: "#85e0ff" }}
              />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Pin
                title="AWS Certified Cloud Practitioner"
                desc1="Issued by Amazon Web Services Training and Certification"
                desc2={
                  <>
                    <a
                      href="https://www.credly.com/badges/6b23f308-494f-42b8-803e-929479c21454/email"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                        marginRight: "2px",
                      }}
                    >
                      {icon}
                    </a>
                  </>
                }
                isMobile={props.isMobile}
              ></Pin>
            </TimelineContent>
          </TimelineItem>
        </Timeline> */}
        <div style={{ textAlign: "center", marginBottom: "5px" }}>
          <div
            data-iframe-width={isMobile ? "200" : "430"}
            data-iframe-height="270"
            data-share-badge-id="6b23f308-494f-42b8-803e-929479c21454"
            data-share-badge-host="https://www.credly.com"
          ></div>
          <div
            data-iframe-width={isMobile ? "200" : "430"}
            data-iframe-height="270"
            data-share-badge-id="92facef8-9069-42ca-a624-7cfc80ce4a18"
            data-share-badge-host="https://www.credly.com"
          ></div>
        </div>
      </Grid>
    </Grid>
  );
}
