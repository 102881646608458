import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";

import About from "./About";
import Certificates from "./Certificates";
import Contact from "./Contact";
import NavBar from "./NavBar";
import Projects from "./Projects";

export default function MainPage(props) {
  const isMobile = useMediaQuery("(max-width: 500px)");
  const [title, setTitle] = useState("Patricia Milou");
  const [aboutRef, setAboutRef] = useState(React.createRef());
  const [certificatesRef, setCertificatesRef] = useState(React.createRef());
  const [projectsRef, setProjectsRef] = useState(React.createRef());
  const [contactRef, setContactRef] = useState(React.createRef());

  const [navBarRef, setNavbarRef] = useState(React.createRef());
  const [aboutAnime, setAboutAnime] = useState(true);
  const [certificatesAnime, setCertificatesAnime] = useState(false);
  const [projectsAnime, setProjectsAnime] = useState(false);
  const [contactAnime, setContactAnime] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToAbout = () => {
    if (aboutRef?.current?.offsetTop)
      window.scrollTo(
        0,
        aboutRef.current.offsetTop -
          navBarRef.current.clientHeight -
          (isMobile ? 20 : 40)
      );
    setAboutAnime(true);
  };

  const scrollToCertificates = () => {
    if (certificatesRef?.current?.offsetTop)
      window.scrollTo(
        0,
        certificatesRef.current.offsetTop -
          navBarRef.current.clientHeight -
          (isMobile ? 20 : 30)
      );
    setCertificatesAnime(true);
    setProjectsAnime(true);
  };

  const scrollToProjects = () => {
    if (projectsRef?.current?.offsetTop)
      window.scrollTo(
        0,
        projectsRef.current.offsetTop -
          navBarRef.current.clientHeight -
          (isMobile ? 20 : 30)
      );
    setProjectsAnime(true);
  };
  const scrollToContact = () => {
    if (contactRef?.current?.offsetTop)
      window.scrollTo(
        0,
        contactRef.current.offsetTop -
          navBarRef.current.clientHeight -
          (isMobile ? 20 : 20)
      );
    setContactAnime(true);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent={"space-evenly"}
      alignItems={"flex-start"}
      spacing={1}
      style={{
        padding: isMobile ? "0px 20px" : "0px 100px",
        width: "100vw",
        backgroundColor: "rgba(253, 45, 131, 1) 100%",
        // height: "100vh",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          position: "sticky",
          top: -2,
          backgroundColor: "#0e052e",
          zIndex: 100,
          padding: isMobile ? "20px 0" : "40px 0",
        }}
        ref={navBarRef}
      >
        <NavBar
          scrollToAbout={scrollToAbout}
          scrollToProjects={scrollToProjects}
          scrollToContact={scrollToContact}
          scrollToCertificates={scrollToCertificates}
        ></NavBar>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <Grid container>
          <Grid item xs={12} ref={aboutRef}>
            <Fade bottom spy={aboutAnime} onReveal={() => setAboutAnime(false)}>
              <About></About>
            </Fade>
          </Grid>
          <Grid item xs={12} ref={certificatesRef}>
            <Fade
              bottom
              spy={certificatesAnime}
              onReveal={() => setCertificatesAnime(false)}
            >
              <Certificates></Certificates>
            </Fade>
          </Grid>
          <Grid item xs={12} ref={projectsRef}>
            <Fade
              bottom
              spy={projectsAnime}
              onReveal={() => setProjectsAnime(false)}
            >
              <Projects></Projects>
            </Fade>
          </Grid>
          <Grid
            item
            xs={12}
            ref={contactRef}
            style={{
              height: navBarRef?.current?.clientHeight
                ? "calc(100vh - " +
                  (navBarRef.current.clientHeight + 20) +
                  "px)"
                : null,
              paddingBottom: "20px",
            }}
          >
            <Fade
              bottom
              spy={contactAnime}
              onReveal={() => setContactAnime(false)}
            >
              <Contact></Contact>
            </Fade>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
